// Dynamic variables.
let map;
let markerId = 0;
let markers = {};

// Constants.
const TCZEW_BOUNDS = {
  north: 54.122581,
  south: 54.059102,
  west: 18.734579,
  east: 18.818693,
};
const RATUSZ = { lat: 54.087004, lng: 18.793241 };

const updateHidden = () => {
  let allPositions = [];

  Object.keys(markers).forEach(key => {
    allPositions.push(markers[key].getPosition().toJSON());
  });

  const hiddenValue = encodeURIComponent(JSON.stringify(allPositions));
  document.getElementById("map-hidden-markers").value = hiddenValue;
};

const placeMarker = (location, map) => {
  const marker = new google.maps.Marker({
    position: location,
    map: map,
  });
  marker.set("markerId", markerId++);

  google.maps.event.addListener(marker, "click", () => {
    marker.setMap(null);
    delete markers[marker.get("markerId")];
    updateHidden();
  });

  map.panTo(location);
  markers[marker.get("markerId")] = marker;
  updateHidden();
};

const initMap = () => {
  map = new google.maps.Map(document.getElementById("map"), {
    center: RATUSZ,
    restriction: {
      latLngBounds: TCZEW_BOUNDS,
      strictBounds: false,
    },
    zoom: 10,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  });

  google.maps.event.addListener(map, "click", event => {
    placeMarker(event.latLng, map);
  });

  const hiddenMarkers = JSON.parse(
    decodeURIComponent(document.getElementById("map-hidden-markers").value)
  );

  hiddenMarkers.forEach(hiddenMarker => {
    placeMarker(hiddenMarker, map);
  });
};

export default initMap;

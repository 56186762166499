/**
 * @file This file contains the scripts that operate on the elements contained by the <header> section on the 'Rowerem Do Pracy' website.
 *
 * @author Stanisław Gregor <gregor.stanislaw@hotmail.com>
 */

const handleCollapses = () => {
  const collapseWrappers = document.querySelectorAll(".collapse-wrapper");

  const toggleCollapse = collapseWrapper =>
    collapseWrapper.classList.toggle("expanded");

  [...collapseWrappers].forEach(wrapper => {
    const collapseToggler = wrapper.querySelector(".collapse-toggler");
    const collapseInner = wrapper.querySelector(".collapse-inner");

    collapseToggler.addEventListener("click", () => {
      toggleCollapse(wrapper);
    });
  });
};

const handleMobileMenu = () => {
  const mobileMenuToggler = document.querySelector(
    ".header__mobile-menu-toggler button"
  );
  const mobileMenu = document.querySelector(".header__mobile-menu");
  const body = document.querySelector("body");

  /**
   * @description Lock/unlock the overflow on the <body>.
   */
  const toggleBodyLock = () => {
    body.classList.toggle("locked-scroll");
  };

  /**
   * @description Toggle the class that manages the visibility of the mobile menu.
   */
  const toggleMobileMenu = () => {
    mobileMenu.classList.toggle("visible");
  };

  /**
   * @description Toggle the class that manages the state of the CSS hamburger. For more information follow this link: https://jonsuh.com/hamburgers/ (list item no 4.).
   */
  const toggleToggler = () => {
    mobileMenuToggler.classList.toggle("is-active");
  };

  mobileMenuToggler.addEventListener("click", () => {
    toggleMobileMenu();
    toggleToggler();
    toggleBodyLock();
  });
};

const header = {
  init: () => {
    handleMobileMenu();
    handleCollapses();
  },
};

export default header;

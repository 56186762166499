/**
 * @description This file contains the scripts that affect or depend on the <footer>.
 *
 * @author Stanisław Gregor <stan.gregor@outlook.com>
 */

/**
 * @description This function handles the swap of the src attribute of the logotypes (there are different SVG images for mobile and desktop).
 *
 * @author Stanisław Gregor <stan.gregor@outlook.com>
 */
const handleLogotypesSrcSwap = () => {
  if (window.innerWidth >= 992) {
    const logotypes = document.querySelectorAll(".footer .logotypes img");

    [...logotypes].forEach(logotype => {
      logotype.src = logotype.dataset.desktopSrc;
    });
  }
};

/**
 * @description This function will handle the 'click' action on the .accept-button in the .footer__cookies-policy element.
 *
 * @author Stanisław Gregor <stan.gregor@outlook.com>
 */
const handleCookieSave = () => {
  /**
   * @description This function will check for the cookiesAgreed cookie in the browser's cache.
   * @returns {boolean}
   */
  const isCookiePresent = () => document.cookie.indexOf("cookiesAgreed") !== -1;

  /**
   * @description This function will delete the cookies information from the document.
   */
  const deleteCookiesInfo = () => {
    // Catch the parent node.
    const footer = document.querySelector("footer");

    // Catch the cookiesPolicy informational node.
    const cookiesPolicy = document.querySelector(".footer__cookies-policy");

    // Remove the cookiesPolicy.
    footer.removeChild(cookiesPolicy);
  };

  if (isCookiePresent()) {
    deleteCookiesInfo();
  } else {
    /**
     * @description This function will save a placeholder cookie in the browser's cache.
     */
    const saveCookie = () => {
      document.cookie =
        "cookiesAgreed=true; expires=Thu, 31 Dec 2020 23:59:59 GMT";
    };

    // Try to catch a button.
    const button = document.querySelector("[data-cookies-agreed]");

    // If the button is present, attach the event listener. If not, abort.
    button
      ? button.addEventListener(
          "click",
          () => {
            saveCookie();
            deleteCookiesInfo();
          },
          { once: true }
        )
      : null;
  }
};

/**
 * @description This object collects all the other, minor functions, to simplify the module exporting.
 *
 * @author Stanisław Gregor <stan.gregor@outlook.com>
 */
const footer = {
  init: () => {
    handleLogotypesSrcSwap();
    handleCookieSave();
  }
};

export default footer;

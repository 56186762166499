import "jquery";
import "bootstrap/dist/js/bootstrap.bundle";

import initMap from "./sections/content/form";
import footer from "./sections/footer";
import header from "./sections/header";

document.addEventListener("DOMContentLoaded", () => {
  footer.init();
  header.init();

  typeof google !== "undefined" ? initMap() : null;
});
